<template>
  <v-sheet
      v-window-resize="() => getSlideWidth()"
      color="transparent" class="py-5">

    <!-- :: HEADER BUTTONS :: -->
    <v-sheet v-if="!hideHeader && !SM" color="transparent" :width="width" class="d-flex justify-center align-center mx-auto overflow-x-auto">
      <h5 v-if="headerTitle" :style="`color : ${wsDARKLIGHT}`" class="mr-5">{{ $t(headerTitle) }} : </h5>

      <v-btn
          @click="selectSection(button)"
          v-for="(button,i) in items" :key="`button_${i}`"
          :color="wsACCENT"
          class="noCaps mr-2"
          :outlined="selectedSection === button.value"
          :text="selectedSection !== button.value"
      >
        {{ $t(button.text) }}
      </v-btn>
    </v-sheet>
    <h2 :style="`color : ${wsDARKER}`" v-else-if="SM" class="text-center">
      {{ $t(selectedSectionText) }}
    </h2>

    <div style="position: relative">

      <!-- :: ARROWS :: -->
      <!-- Arrow Left -->
      <div v-if="!hideArrows && !notCentered" @click="prevSlide" class="d-flex justify-center"
           :class="[ {'align-center' : !bottomArrows} ,  {'align-end' : bottomArrows} , {'pb-12' : bottomArrows}]"
           style=" z-index : 10"
           :style="!notCentered ? 'position:absolute; height: 100%;' : null"
      >
        <slot name="arrow.left">
          <v-btn :small="SM" :min-width="SM ? '30' : '40'" :class="leftArrowStyle" :color="wsATTENTION"  width="10px"
                 class="px-1 wsRoundedHalf" elevation="0"  dark
          >
            <v-icon :small="SM"  size="22" >mdi-arrow-left</v-icon>
          </v-btn>
        </slot>
      </div>
      <!-- Arrow Right -->
      <div v-if="!hideArrows && !notCentered" @click="nextSlide" class="d-flex justify-center"
           :class="[ {'align-center' : !bottomArrows} ,  {'align-end' : bottomArrows} , {'pb-12' : bottomArrows}]"
           style=" z-index : 10"
           :style="!notCentered ? 'position:absolute; right: 0; height: 100%;' : null"
      >
        <slot name="arrow.right">
          <v-btn :small="SM" :min-width="SM ? '30' : '40'"  width="10px" :class="rightArrowStyle"  :color="wsATTENTION"
                 class="px-0 wsRoundedHalf" elevation="0"  dark><v-icon :small="SM" size="22" >mdi-arrow-right</v-icon></v-btn>
        </slot>
      </div>

      <div v-if="!hideArrows && notCentered" class="d-flex justify-space-between">

        <div>
          <slot name="title"></slot>
        </div>

        <div class="d-flex">
          <div @click="prevSlide" class="d-flex justify-center"
               :class="[ {'align-center' : !bottomArrows} ,  {'align-end' : bottomArrows} , {'pb-12' : bottomArrows}]"
               style=" z-index : 10"
               :style="!notCentered ? 'position:absolute; height: 100%;' : null"
          >
            <slot name="arrow.left">
              <v-btn :small="SM" :min-width="SM ? '30' : '40'" :class="leftArrowStyle" :color="wsATTENTION"  width="10px"
                     class="px-1 wsRoundedHalf" elevation="0"  dark
              >
                <v-icon :small="SM"  size="22" >mdi-arrow-left</v-icon>
              </v-btn>
            </slot>
          </div>
          <!-- Arrow Right -->
          <div @click="nextSlide" class="d-flex justify-center"
               :class="[ {'align-center' : !bottomArrows} ,  {'align-end' : bottomArrows} , {'pb-12' : bottomArrows}]"
               style=" z-index : 10"
               :style="!notCentered ? 'position:absolute; right: 0; height: 100%;' : null"
          >
            <slot name="arrow.right">
              <v-btn :small="SM" :min-width="SM ? '30' : '40'"  width="10px" :class="rightArrowStyle"  :color="wsATTENTION"
                     class="px-0 wsRoundedHalf" elevation="0"  dark><v-icon :small="SM" size="22" >mdi-arrow-right</v-icon></v-btn>
            </slot>
          </div>
        </div>

      </div>




      <v-sheet color="transparent" class="py-6 mb-10" style="overflow: hidden">
        <v-sheet color="transparent"  :width="width" :height="height" class=" d-flex " style="position: relative;"
                 :class="[{'mx-auto' : !notCentered}]"
        >
          <!-- :: SLIDES :: -->
          <v-sheet
              :ref="`slide_${index}`"
              v-for="(item,index) in content" :key="`item_${index}`"
              :style="slideStyle(index)"
              :color="item.item.background || 'transparent'"
              :min-width="width"
              :width="width"
              :height="height"
              class="slideTransition"

              :class="[{'nonActiveSlide' : selectedSlideIndex !== index && !allActive} , {'wsRoundedHalf' : rounded}]"
          >

            <slot name="item" :item="item" :content="item.item" :sectionIndex="selectedSlideSectionIndex">
              <template v-if="item.item.value">
                <slot :name="item.item.value">
                </slot>
              </template>
              <v-img v-if="onlyImageSlider"
                     transition="xxx"
                     :src="item.item" :height="height" :width="width" ></v-img>
            </slot>

          </v-sheet>
          <!-- :: DOTS :: -->
          <div
              v-if="displayDots"
              style="position:absolute; right: 0; left: 0; bottom: 12px; height: 50px; z-index : 2"
              class="d-flex align-center justify-center"
          >
            <v-sheet
                v-for="i in selectedSectionItemsLength" :key="`dot${i}`"
                :color=" ((i - 1) === selectedSlideSectionIndex )?  wsATTENTION : wsDARKLIGHT"
                style="border-radius: 50%; transition: all 0.5s ease;"
                class="mr-3" height="12" width="12"

            >
            </v-sheet>

          </div>

        </v-sheet>
      </v-sheet>
    </div>


  </v-sheet>
</template>

<script>
export default {
  name: "homeSlider",
  props : {
    items : {
      type : Array,
      default() { return [] }
    },
    headerTitle : {
      type : String
    },
    hideArrows : {
      type : Boolean,
      default : false
    },
    hideHeader : {
      type : Boolean,
      default : false
    },
    onlyImageSlider : {
      type : Boolean,
      default : false
    },
    height : {
      type : String,
      default : '600'
    },
    width : {
      type : String,
      default : '900'
    },
    displayDots : {
      type : Boolean,
      default : false
    },
    leftArrowStyle : {
      type : String,
      default : 'ml-5'
    },
    rightArrowStyle : {
      type : String,
      default : 'mr-5'
    },
    rounded : {
      type : Boolean,
      default : true
    },
    bottomArrows : {
      type : Boolean,
      default : false
    },
    notCentered : {
      type : Boolean,
      default : false
    },
    allActive : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      selectedSlideIndex : 0,
      sections : [],
      selectedSection : '',
      slide : {},
      slideWidth : 900
    }
  },
  computed : {
    selectedSectionText() {
      if ( this.items.length === 0 ) {
        return ''
      }
      const section = this.items.find(el => el.value === this.selectedSection )

      if (!section ) {
        return ''
      }

      return section.text
    },
    selectedSlideSectionIndex() {
      if ( this.content.length === 0 ) {
        return 0
      }

      let item = this.content[this.selectedSlideIndex]

      let index = this.content.filter( el=> el.section === this.selectedSection).findIndex(el => this.EQ(el.item , item.item) )

      if ( index === -1 ) {
        return 0
      }

      return index
    },
    selectedSectionItemsLength() {
      if ( !this.selectedSection ) {
        return 0
      }
      if ( this.content.length === 0 ) {
        return 0
      }
      return this.content.filter(el => el.section === this.selectedSection).length
    },
    showNextArrow() {
      if ( this.content.length === 0 ) {
        return false
      }
      return this.selectedSlideIndex !== this.content.length - 1
    },
    showPrevArrow() {
      return this.selectedSlideIndex !== 0
    },
    content() {
      if ( !this.items ) {
        return []
      }
      let items = []
      this.items.forEach(el=> {
        if ( el.items )  {
          el.items.forEach(item => { items.push( { item : item , section : el.value }) })
        } else if (el.content) {
          items.push( { item : el.content , section : el.value })
        } else if (el.asset) {
          let asset = require(`@/assets/${el.asset}`)
          items.push( { item : asset , section : el.value })
        } else {
          items.push( { item : el , section : el.value })
        }
      })

      return items
    }
  },
  watch : {
    SM() {
      this.getSlideWidth()
      setTimeout(this.getSlideWidth , 550 )
    },
    width() {
      this.getSlideWidth()
      setTimeout(this.getSlideWidth , 550 )
    },
    selectedSlideIndex() {
      let item = this.content[this.selectedSlideIndex]
      if ( item ) {
        if ( item.section !== this.selectedSection ) {
          this.selectedSection = item.section
        }
      }
    }
  },
  methods : {
    nextSlide() {
     this.getSlideWidth()
      if ( !this.showNextArrow ) {
        return
      }
      this.selectedSlideIndex++
    },
    prevSlide() {
     this.getSlideWidth()
      if ( !this.showPrevArrow ) {
        return
      }
      this.selectedSlideIndex--
    },
    selectSection(button) {
      this.getSlideWidth()
      this.selectedSection = button.value
      let index = this.content.findIndex(el => el.section === button.value)
      if ( index !== -1 ) {
        this.selectedSlideIndex = index
      }
      setTimeout(this.getSlideWidth , 550 )
    },
    slideStyle(index) {
      let style = `transform: translateX(-${this.slideWidth * (this.selectedSlideIndex)}px) `
      if ( index !== this.selectedSlideIndex && !this.allActive) {
        style += 'scale(0.92);'
        style += 'opacity : 0.75;'
      }

      return style

    },
    getSlideWidth() {

      this.slide = this.$refs['slide_' + this.selectedSlideIndex][0]
      if ( this.slide ) {
        let rect = this.slide.$el.getBoundingClientRect()
        let width = rect.width
        if ( this.width.includes('%') ) {
          this.slideWidth = width
        } else {
          this.slideWidth = this.width
        }

      }
    }
  },
  mounted() {
    if ( this.items && this.items.length > 0 ) {
      this.selectedSection = this.items[0].value
    }
    setTimeout(()=> {

      this.getSlideWidth()
    })
  }
}
</script>

<style scoped>
.slideTransition {
  transition: all 0.5s ease;
}


</style>